const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\TrackerPage\\VehicleTag.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";

import { Tag } from "antd";
import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";

const VehicleTag = (props) => {
  const t = useTranslation();
  if (!props.vehicle) {
    return (
      React.createElement(Tag, { color: "red", icon: React.createElement(Icon, { icon: "fa:unlink", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
        , t("wehn:tracker.tags.unattached")
      )
    );
  }
  const { type, sources, unknownTag } = props.vehicle;
  return (
    React.createElement(Tag, {
      icon: 
        type === "count" ? null : (
          React.createElement(Icon, { icon: type === "unattached" ? "fa:unlink" : "fa:link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
        )
      ,
      color: 
        type === "attached"
          ? "green"
          : type === "unattached"
          ? "red"
          : type === "count"
          ? "grey"
          : "yellow"
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}

      , type === "attached"
        ? _optionalChain([sources, 'optionalAccess', _ => _[0], 'optionalAccess', _2 => _2.name])
        : type === "unattached"
        ? t("wehn:tracker.tags.unattached")
        : type === "count"
        ? t("wehn:tracker.tags.count", {
            vehicles: "" + (_optionalChain([sources, 'optionalAccess', _3 => _3.length]) || 0),
          })
        : t("wehn:tracker.tags.unkown", { tag: unknownTag || "??" })
    )
  );
};

export default VehicleTag;
