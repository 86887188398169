import Parse from "parse";

















export class WEHN_IOT_Decoder extends Parse.Object {
  static __initStatic() {this.className = "WEHN_IOT_Decoder"}

  constructor(data) {
    super("WEHN_IOT_Decoder", data );
  }

  get config() {
    return super.get("config");
  }
  set config(value) {
    super.set("config", value);
  }
  get dataId() {
    return super.get("dataId");
  }
  set dataId(value) {
    super.set("dataId", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get vehicleType() {
    return super.get("vehicleType");
  }
  set vehicleType(value) {
    super.set("vehicleType", value);
  }
} WEHN_IOT_Decoder.__initStatic();

Parse.Object.registerSubclass("WEHN_IOT_Decoder", WEHN_IOT_Decoder);
