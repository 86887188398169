const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\VehicleFilter\\FilterManagementView.tsx";import {
  AdminLayout,

  useSource,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { $parse, } from "@opendash/plugin-parse";
import { Button, List, } from "antd";
import { useParseQuery } from "parse-hooks";
import React, { } from "react";
import { WEHN_IOT_VehicleFilter } from "../../types";
import Parse from "parse";
import EditFilterValueDialog from "./EditFilterValuesDialog";
import { useTenantSource } from "../../hooks/useTenantSource";
import { Icon } from "@opendash/icons";
import { AdminToolbar } from "@opendash/ui";

 
const FilterManagementView = (props) => {
  const [source, setSource, allSources] = useSource();
  const { source: tenantSource } = useTenantSource() || { source: null };
  const [filter, setFilter] = useUrlParam(
    "editFilter",
    [] ,
    "array"
  );
  if (tenantSource !== null && source.id !== tenantSource.id) {
    setSource(tenantSource.id);
  }
  const t = useTranslation();
  const query = React.useMemo(() => {
    return new Parse.Query(WEHN_IOT_VehicleFilter);
  }, [source]);
  const { result, reload, count, error, loading } = useParseQuery(query);

  // const tquery = React.useMemo(() => {
  //   return new Parse.Query(OD3_Tenant);
  // }, []);
  // const { result: tenants } = useParseQuery(tquery);

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
      , React.createElement(AdminToolbar, {
        title: t("wehn:filter.label"),
        actions: [
          React.createElement(Button, {
            type: "primary",
            onClick: () => {
              $parse.ui.createObject("WEHN_IOT_VehicleFilter");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}

            , t("opendash:dashboard.assistant.modal.save")
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
)

      , React.createElement('div', {
        style: {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "16px",
          background: "#fff",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}

        /* {tenants && tenants.length > 1 && (
          <Select
            options={tenants.map((tenant) => ({
              label: tenant.get("label"),
              value: tenant.id,
            }))}
          ></Select>
        )} */
        , React.createElement(List, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
          , result.map((filter, index) => {
            return (
              React.createElement(React.Fragment, null
                , React.createElement(List.Item, { key: filter.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
                  , React.createElement(List.Item.Meta, { title: filter.get("label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} )
                  , React.createElement('div', {
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}

                    , React.createElement(Button, {
                      type: "primary",
                      icon: React.createElement(Icon, { icon: "fa:edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}),
                      onClick: () => {
                        setFilter([filter.id, "values"]);
                        //setOpen("true");
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
                    )
                    , React.createElement(Button, {
                      type: "default",
                      icon: React.createElement(Icon, { icon: "fa:lock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}),
                      onClick: () => {
                        $parse.ui.editObjectACL(
                          "WEHN_IOT_VehicleFilter",
                          filter.id
                        );
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
                    )

                    /* <Button type="primary">
                    {t("wehn:admin.filter.edit.loadType")}
                  </Button> */
                  )
                )
              )
            );
          })
        )
        , React.createElement(EditFilterValueDialog, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 116}} )
      )
    )
  );
};

export default FilterManagementView;
