
import { useParseQuery } from "parse-hooks";
import React from "react";
import Parse from "parse";
import { WEHN_IOT_VehicleFilter } from "../types";
export default function useFilters() {
  const [filters, setFilters] = React.useState([]);

  const query = React.useMemo(() => {
    return new Parse.Query(WEHN_IOT_VehicleFilter);
  }, []);
  const { result, error, loading: queryLoading, reload } = useParseQuery(query);

  React.useEffect(() => {
    if (result) {
      setFilters(result);
    }
  }, [result]);

  return { filters, queryLoading, error, reload };
}
