 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useSource } from "@opendash/core";
import { useParseQuery } from "parse-hooks";
import React from "react";
import Parse from "parse";
import { OD3_Source, WEHN_Vehicles } from "../types";
export default function useFilters(sourceIds) {
  const [source, setSource] = useSource();
  const query = React.useMemo(() => {
    const innerQ = new Parse.Query(WEHN_Vehicles);
    if (sourceIds) {
      innerQ.containedIn(
        "source",
        sourceIds.map((cSource) => new OD3_Source({ id: cSource }))
      );
    } else {
      innerQ.equalTo("source", new OD3_Source({ id: source.id }));
    }
    return innerQ;
  }, [_optionalChain([sourceIds, 'optionalAccess', _ => _.join, 'call', _2 => _2(",")]) || ""]);
  const {
    result: vehicles,
    error,
    loading: queryLoading,
    reload,
  } = useParseQuery(query);

  return { vehicles, queryLoading, error, reload };
}
