import Parse from "parse";









export class OD3_BDE_ListEntry extends Parse.Object {
  static __initStatic() {this.className = "OD3_BDE_ListEntry"}

  constructor(data) {
    super("OD3_BDE_ListEntry", data );
  }

} OD3_BDE_ListEntry.__initStatic();

Parse.Object.registerSubclass("OD3_BDE_ListEntry", OD3_BDE_ListEntry);
