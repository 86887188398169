import Parse from "parse";
















export class WEHN_IOT_FilterView extends Parse.Object {
  static __initStatic() {this.className = "WEHN_IOT_FilterView"}

  constructor(data) {
    super("WEHN_IOT_FilterView", data );
  }

  get customFilter() {
    return super.get("customFilter");
  }
  set customFilter(value) {
    super.set("customFilter", value);
  }
  get filters() {
    return super.relation("filters");
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} WEHN_IOT_FilterView.__initStatic();

Parse.Object.registerSubclass("WEHN_IOT_FilterView", WEHN_IOT_FilterView);
