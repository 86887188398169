import { useSource } from "@opendash/core";

import {


  useDataItems,
  useDataService,
} from "@opendash/plugin-timeseries";
import { useEffect, useState } from "react";
const itemIds = [
  ["signal", 1, "fa:signal"],
  ["fuellstand", 0, "fa:percent"],
  ["IRSheartbeat", 0, "fa:sync"],
  ["online", 0, "fa:sync"],
  ["betriebsstunden", 0, "fa:clock"],
];






function useVehicleData() {
  const items = useDataItems();
  const DataService = useDataService();
  const [cSource, setSource, allSources, loading, enabled] = useSource();
  const itemsInOverview = itemIds
    .map(([cId, index, icon]) => {
      const item = items.find(
        (item) => item.id === cId && item.source === cSource.tag
      );
      if (item) return [item, index, icon];
      return null;
    })
    .filter((item) => !!item) ;

  const currentAttachedVehicleItem = DataService._getOrUndefinedSync(
    cSource.tag,
    "attachedVehicle"
  );

  const fetchVehicleValue = async () => {
    let data = [] ;
    if (!currentAttachedVehicleItem) {
    } else {
      data = await DataService.fetchDimensionValues(
        currentAttachedVehicleItem,
        0,
        { limit: 1, live: true }
      );
    }

    if (data[0]) {
      const vehicle = allSources.find((source) => source.tag === data[0].value);
      setAttachedVehicle({
        type: vehicle
          ? "attached"
          : data[0].value === ""
          ? "unattached"
          : "unknownVehicle",
        sources: vehicle ? [vehicle] : undefined,
        unknownTag: vehicle ? undefined : (data[0].value ),
      });
    } else {
      const childrenVehicle = allSources.filter(
        (child) => child.parent === cSource.id
      );
      if (childrenVehicle.length > 0) {
        setAttachedVehicle({ type: "count", sources: childrenVehicle });
      } else {
        setAttachedVehicle({ type: "unattached" });
      }
    }
  };
  useEffect(() => {
    fetchVehicleValue();
  }, [currentAttachedVehicleItem]);

  const [attachedVehicle, setAttachedVehicle] =
    useState(null);
  return {
    itemsInOverview,
    attachedVehicle,
  };
}
export { useVehicleData, };
