const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\Decoder\\NumberNumberDecoder.tsx";import * as React from "react";

import { useTranslation, produce, } from "@opendash/core";

import { Row, Col, Divider } from "antd";


import NumberDecoder from "./NumberDecoder";





export default (props) => {
  const t = useTranslation();

  return (
    React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , React.createElement(NumberDecoder, {
          update: (updatedNumber) => {
            props.update(
              produce(props.decoder, (draft) => {
                draft.config.number1 = updatedNumber;
              })
            );
          },
          number: props.decoder.config.number1,
          title: t("wehn:admin.decoder.number2b.title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        )
        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} )

        , React.createElement(NumberDecoder, {
          update: (updatedNumber) => {
            props.update(
              produce(props.decoder, (draft) => {
                draft.config.number2 = updatedNumber;
              })
            );
          },
          number: props.decoder.config.number2,
          title: t("wehn:admin.decoder.number2b.title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        )
      )
    )
  );
};
