 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }





import { $parse, } from "@opendash/plugin-parse";

import DecoderOptionsComponent from "./components/Decoder/DecoderOptionsComponent";
import SensorDecoder from "./components/Decoder/SensorDecoderComponent";
import VehicleFilter from "./components/VehicleFilter/FilterManagementView";
import { VehicleTracker } from "./components/TrackerPage/VehicleTracker";

export class WehnPlugin  {
  __init() {this.name = "WehnPlugin"}

  __init2() {this._config = {}}

  constructor(config) {;WehnPlugin.prototype.__init.call(this);WehnPlugin.prototype.__init2.call(this);
    this._config = config;
  }

  async onFactory(factory) {
    await factory.registerTranslationResolver(
      "en",
      "wehn",
      async () => await import("./translations/en.json")
    );
    await factory.registerTranslationResolver(
      "de",
      "wehn",
      async () => await import("./translations/de.json")
    );
    await factory.registerTranslationResolver(
      "zh_Hans",
      "wehn",
      async () => await import("./translations/zh_Hans.json")
    );

    factory.registerRoute({
      path: "/admin/wehn/decoder/*",
      props: {},
      component: async () => ({ default: SensorDecoder }),
    });
    factory.registerRoute({
      path: "admin/filter/*",
      props: {},
      component: async () => ({ default: VehicleFilter }),
    });
    factory.registerRoute({
      path: "/irs/tracker",
      component: () => Promise.resolve({ default: VehicleTracker }),
    });

    factory.registerStaticNavigationGroup({
      label: "wehn:navigation.decoder.label",
      order: 40,
      id: "admin/wehn",
    });
    factory.registerStaticNavigationItem({
      id: "admin/wehn/decoder",
      group: "admin/wehn",
      place: "sidebar",
      order: 100,
      label: "wehn:navigation.decoder.label",
      icon: "fa:cogs",
      color: "#676767",
      link: "/admin/wehn/decoder",
      routeCondition: "**",
      activeCondition: "/admin/wehn/decoder",
      permission: "wehn:can-access-decoder",
    });
    factory.registerStaticNavigationItem({
      id: "admin/wehn/tenant",
      group: "admin/parse",
      place: "sidebar",
      order: 110,
      label: "wehn:classes.OD3_Tenant.label_plural",
      icon: "fa:users",
      color: "#FFF",
      link: "/admin/parse/OD3_Tenant",
      routeCondition: "**",
      activeCondition: "/admin/wehn/decoder",
      permission: "wehn:tenant-admin",
    });
    factory.registerStaticNavigationItem({
      id: "admin/wehn/filter",
      group: "admin/wehn",
      place: "sidebar",
      order: 110,
      label: "wehn:filter.label",
      icon: "fa:filter",
      color: "#676767",
      link: "/admin/filter",
      routeCondition: "**",
      activeCondition: "/admin/wehn/filter",
      permission: "wehn:can-access-decoder",
    });
    $parse.ui.setClassConfig({
      className: "WEHN_IOT_Decoder",
      titleFields: ["label", "dataId"],
      displayFields: ["label", "dataId", "type", "config", "vehicleType"],
      createFields: ["label", "dataId", "type", "vehicleType"],
      editFields: ["label", "dataId", "type", "vehicleType"],
      translationPrefix: "wehn:classes.",
      asSelect: {
        type: [
          ["BitsOnly", "wehn:decoder.types.bitsonly"],
          ["BitsNumber", "wehn:decoder.types.bitsnumber"],
          ["NumberNumber", "wehn:decoder.types.numbernumber"],
          ["Number", "wehn:decoder.types.number"],
        ],
        vehicleType: [
          ["trailer", "wehn:admin.decoder.vehicleType.trailer"],
          ["truck", "wehn:admin.decoder.vehicleType.truck"],
        ],
      },
      customFields: {
        config: (val) => {
          return DecoderOptionsComponent(val);
        },
      },
    });
    $parse.ui.setClassConfig({
      className: "WEHN_IOT_TruckLoadType",
      titleFields: ["label"],
      displayFields: ["label"],
      createFields: ["label", "key"],
      editFields: ["label", "key"],
      translationPrefix: "wehn:classes.",
      defaultACL: () => {
        if (!$parse.user.id()) {
          return {};
        }
        return {
          ["" + $parse.user.id()]: {
            read: true,
            write: true,
          },
        };
      },
    });
    $parse.ui.setClassConfig({
      className: "WEHN_IOT_VehicleFilter",
      titleFields: ["label"],
      displayFields: ["label"],
      createFields: ["label", "source"],
      editFields: ["label", "source", "values", "loadType"],
      translationPrefix: "wehn:classes.",

      defaultACL: () => {
        if (!$parse.user.id()) {
          return {};
        }
        return {
          ["" + $parse.user.id()]: {
            read: true,
            write: true,
          },
        };
      },
    });

    $parse.ui.setDefaultView("WEHN_IOT_VehicleFilter", {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: "OD3_Tenant",
      titleFields: ["label"],
      displayFields: ["label", "tagPrefix"],
      createFields: ["label", "tagPrefix"],
      editFields: ["label"],
      translationPrefix: "wehn:classes.",
    });
    $parse.ui.setDefaultView("OD3_Tenant", {
      type: "table",
    });
    const sourceConfig = $parse.ui.getClassConfig("OD3_Source");
    sourceConfig.displayFields = [
      ...(_optionalChain([sourceConfig, 'optionalAccess', _ => _.displayFields]) || []),
      "tenant",
    ];
    console.log({ ...sourceConfig });
    //$parse.ui.setClassConfig(sourceConfig!);
  }
}
