import Parse from "parse";
















export class WEHN_Vehicles extends Parse.Object {
  static __initStatic() {this.className = "WEHN_Vehicles"}

  constructor(data) {
    super("WEHN_Vehicles", data );
  }

  get fahrgestellnummer() {
    return super.get("fahrgestellnummer");
  }
  set fahrgestellnummer(value) {
    super.set("fahrgestellnummer", value);
  }
  get info() {
    return super.get("info");
  }
  set info(value) {
    super.set("info", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get vehicleID() {
    return super.get("vehicleID");
  }
  set vehicleID(value) {
    super.set("vehicleID", value);
  }
} WEHN_Vehicles.__initStatic();

Parse.Object.registerSubclass("WEHN_Vehicles", WEHN_Vehicles);
