 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useOpenDashServices } from "@opendash/core";
import { $parse } from "@opendash/plugin-parse";
import React from "react";
import { OD3_Tenant } from "../types";
import { useParseQueryFirst } from "parse-hooks";
import Parse from "parse";





export function useTenantSource(
  tenantId = _optionalChain([$parse, 'access', _ => _.user, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant"), 'optionalAccess', _6 => _6.id])
) {
  const [tSource, setTSource] = React.useState(null);
  const tQuery = React.useMemo(() => {
    return new Parse.Query(OD3_Tenant).equalTo("objectId", tenantId || "");
  }, [tenantId]);
  const { SourceService } = useOpenDashServices();
  const { result: tenant } = useParseQueryFirst(tQuery);

  React.useEffect(() => {
    if (!tenant) {
      setTSource(null);
      return;
    }
    const root = SourceService.getAll().find(
      (source) => source.id === _optionalChain([tenant, 'optionalAccess', _7 => _7.get, 'call', _8 => _8("dataSource"), 'optionalAccess', _9 => _9.id])
    );
    if (!root) {
      setTSource(null);
      return;
    }
    setTSource({ source: root, children: SourceService.getDescendents(root) });
  }, [tenant]);
  return tSource;
}
