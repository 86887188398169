

import { produce } from "@opendash/core";








function adjustRule(rule, sourceTag) {
  if (!rule)
    throw new Error(
      "Rule is required for adjustmend to source tag " + sourceTag
    );
  if (rule.type === "rule") {
    return produce(rule, (draft) => {
      draft.source = sourceTag;
    });
  } else {
    return produce(rule, (draft) => {
      draft.children = rule.children.map((innerRule) => {
        return adjustRule(innerRule, sourceTag);
      });
    });
  }
}
function prepareFilterForSource(
  filters,
  sourceTag
) {
  return filters.map((filter) => adjustRule(filter.values, sourceTag));
}
function filterRecursiv(
  filter,
  v,
  refValue = undefined
) {
  if (!filter) return true;
  try {
    if (filter.type === "and") {
      return filter.children.every((childFilter) =>
        filterRecursiv(childFilter, v, refValue)
      );
    }

    if (filter.type === "or") {
      return filter.children.some((childFilter) =>
        filterRecursiv(childFilter, v, refValue)
      );
    }

    if (filter.type === "rule") {
      const value = Array.isArray(v.value)
        ? v.value[filter.dimension]
        : v.value;
      const stringValue = value ;
      const numberValue = value ;
      const booleanValue = value ;

      switch (filter.rule.type) {
        case "string_equals":
          return stringValue === filter.rule.string;
        case "string_equals_not":
          return stringValue !== filter.rule.string;
        case "string_includes":
          return stringValue.includes(filter.rule.string);
        case "string_includes_not":
          return !stringValue.includes(filter.rule.string);
        case "string_starts_with":
          return stringValue.startsWith(filter.rule.string);
        case "string_starts_with_not":
          return !stringValue.startsWith(filter.rule.string);
        case "string_ends_with":
          return stringValue.endsWith(filter.rule.string);
        case "string_ends_with_not":
          return !stringValue.endsWith(filter.rule.string);
        case "boolean_true":
          return booleanValue === true;
        case "boolean_false":
          return booleanValue === false;
        case "number_equals":
          return numberValue === filter.rule.value;
        case "number_equals_not":
          return numberValue !== filter.rule.value;
        case "number_gt":
          return numberValue > filter.rule.value;
        case "number_lt":
          return numberValue < filter.rule.value;
        case "number_in_range":
          return numberValue < filter.rule.max && numberValue > filter.rule.min;
        case "number_out_of_range":
          return numberValue > filter.rule.max && numberValue < filter.rule.min;

        default:
          throw new Error("Rule not implemented.");
      }
    }

    return false;
  } catch (error) {
    return false;
  }
}

export {
  prepareFilterForSource,
  filterRecursiv,
  adjustRule as prepareRuleForSource,
};
