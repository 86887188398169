const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\TrackerPage\\TrackerTableComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";
import { useParseQueryFirst } from "parse-hooks";
 


import {

  useOpenDashServices,
  useTranslation,
} from "@opendash/core";
import useTrackerTableData from "../../hooks/useTrackerTableData";
import { Table } from "antd";
 











const TrackerTableComponent = ({ sources }) => {
  const t = useTranslation();
  const { SourceService } = useOpenDashServices();
  const tableConfigQuery = React.useMemo(() => {
    return new Parse.Query("OD3_Config")
      .equalTo("key", "WEHN_IOT_OVERVIEW_COLS")
      .descending("priority")
      .limit(1);
  }, []);
  const {
    result: tableConfig,
    loading,
    error,
    reload,
  } = useParseQueryFirst(tableConfigQuery);
  const colConfig = JSON.parse(_optionalChain([tableConfig, 'optionalAccess', _ => _.get, 'call', _2 => _2("value")]) || '{"columns":[]}')
    .columns ;
  const columns = colConfig.map((col) => {
    return {
      title: t(col.label),
      key: `${col.type}_${col.key}`,
      dataIndex: `${col.type}_${col.key}`,
    };
  });

  const data = useTrackerTableData(sources, colConfig);
  console.log({ data });
  return (
    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      , React.createElement(Table, {
        columns: columns,
        dataSource: data,
        onRow: (record, index) => {
          return {
            onClick: (event) => {
              if (index || index === 0) {
                SourceService.setCurrent(sources[index].id);
              }
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
)
    )
  );
};

export default TrackerTableComponent;
