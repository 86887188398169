import Parse from "parse";






















export class OD3_Tenant extends Parse.Object {
  static __initStatic() {this.className = "OD3_Tenant"}

  constructor(data) {
    super("OD3_Tenant", data );
  }

  get dataProtectionUrl() {
    return super.get("dataProtectionUrl");
  }
  set dataProtectionUrl(value) {
    super.set("dataProtectionUrl", value);
  }
  get dataSource() {
    return super.get("dataSource");
  }
  set dataSource(value) {
    super.set("dataSource", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get hasDataSource() {
    return super.get("hasDataSource");
  }
  set hasDataSource(value) {
    super.set("hasDataSource", value);
  }
  get icon() {
    return super.get("icon");
  }
  set icon(value) {
    super.set("icon", value);
  }
  get imprintUrl() {
    return super.get("imprintUrl");
  }
  set imprintUrl(value) {
    super.set("imprintUrl", value);
  }
  get interactWithOtherTenants() {
    return super.get("interactWithOtherTenants");
  }
  set interactWithOtherTenants(value) {
    super.set("interactWithOtherTenants", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get logo() {
    return super.get("logo");
  }
  set logo(value) {
    super.set("logo", value);
  }
  get public() {
    return super.get("public");
  }
  set public(value) {
    super.set("public", value);
  }
  get tagPrefix() {
    return super.get("tagPrefix");
  }
  set tagPrefix(value) {
    super.set("tagPrefix", value);
  }
} OD3_Tenant.__initStatic();

Parse.Object.registerSubclass("OD3_Tenant", OD3_Tenant);
