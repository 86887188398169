const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\Decoder\\NumberDecoder.tsx";import * as React from "react";
import { useTranslation, produce, } from "@opendash/core";

import { Input, Switch, Descriptions, InputNumber } from "antd";



















export default (props) => {
  const t = useTranslation();

  return (
    React.createElement(Descriptions, { title: t(props.title), bordered: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      , React.createElement(React.Fragment, null
        , React.createElement(Descriptions.Item, {
          label: t("wehn:admin.decoder.number2b.title"),
          span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}

          , React.createElement(Switch, {
            checked: props.number.enabled,
            onChange: () => {
              props.update(
                produce(props.number, (draft) => {
                  draft.enabled = !props.number.enabled;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
          )
        )
        , React.createElement(Descriptions.Item, {
          span: 3,
          label: t("wehn:admin.decoder.number2b.label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}

          , React.createElement(Input, {
            disabled: !props.number.enabled,
            value: props.number.label,
            onChange: (e) => {
              const val = e.target.value;
              props.update(
                produce(props.number, (draft) => {
                  draft.label = val;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
)
        )
        , React.createElement(Descriptions.Item, {
          span: 3,
          label: t("wehn:admin.decoder.number2b.unit"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}

          , React.createElement(Input, {
            disabled: !props.number.enabled,
            value: props.number.unit,
            onChange: (e) => {
              const val = e.target.value;
              props.update(
                produce(props.number, (draft) => {
                  draft.unit = val;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
)
        )
        , React.createElement(Descriptions.Item, {
          span: 3,
          label: t("wehn:admin.decoder.number2b.offset"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}

          , React.createElement(InputNumber, {
            disabled: !props.number.enabled,
            value: props.number.offset,
            onChange: (e) => {
              const val = e;
              props.update(
                produce(props.number, (draft) => {
                  draft.offset = val;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
)
        )
        , React.createElement(Descriptions.Item, {
          span: 3,
          label: t("wehn:admin.decoder.number2b.factor"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}

          , React.createElement(InputNumber, {
            disabled: !props.number.enabled,
            value: props.number.factor,
            onChange: (e) => {
              const val = e;
              props.update(
                produce(props.number, (draft) => {
                  draft.factor = val;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
)
        )
      )
    )
  );
};
