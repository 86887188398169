const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\Decoder\\SensorDecoderComponent.tsx";import * as React from "react";

import { useTranslation, produce, AdminLayout } from "@opendash/core";

import {
  Button,
  Row,
  Col,

  Descriptions,
  Tag,
  Drawer,
  Divider,

} from "antd";
import { ParseSetView } from "@opendash/plugin-parse";
import { Icon } from "@opendash/icons";

import BitsDecoder from "./BitsDecoder";
import * as Parse from "parse";



import BitsNumberDecoder from "./BitsNumberDecoder";
import NumberNumberDecoder from "./NumberNumberDecoder";
import NumberDecoder from "./NumberDecoder";
const Decoder = Parse.Object.extend("WEHN_IOT_Decoder");
export default () => {
  const t = useTranslation();
  const [selected, setSelected] = React.useState(null);
  const [drawerItem, setDrawerItem] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    if (selected) {
      if (!selected.config) {
        const newSelected = produce(selected, (draft) => {
          if (draft.type === "BitsOnly") {
            draft.config = {
              bits: [...new Array(32).keys()].map((i) => {
                return { enabled: false, label: "" };
              }),
            };
          }
          const numberTemplate = {
            enabled: false,
            label: "",
            unit: "",
            offset: 0,
            factor: 1,
          };
          if (draft.type === "BitsNumber") {
            draft.config = {
              bits: [...Object.keys(new Array(16))].map((i) => {
                return { enabled: false, label: "" };
              }),
              number: numberTemplate,
            };
          }
          if (draft.type === "NumberNumber") {
            draft.config = {
              number1: numberTemplate,
              number2: numberTemplate,
            };
          }
          if (draft.type === "Number") {
            draft.config = {
              number: numberTemplate,
            };
          }
        });
        setSelected(newSelected);
        return;
      }

      setDrawerItem(selected);
      setDrawerOpen(true);
      return;
    }
    setDrawerOpen(false);
  }, [selected]);
  const saveDrawerItem = async () => {
    if (drawerItem === null) return;
    const save = Decoder.createWithoutData(drawerItem.objectId);
    save.set("config", drawerItem.config);
    await save.save();
  };
  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      , React.createElement(ParseSetView, {
        className: "WEHN_IOT_Decoder",
        view: 
          {
            type: "table",
            fields: ["label", "dataId", "type", "vehicleType"],
            customCols: [
              {
                title: t("wehn:admin.decoder.configTitle"),
                key: "config",
                render: (v, row) => {
                  return (
                    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
                      , React.createElement(Button, {
                        icon: React.createElement(Icon, { icon: "fa:edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} ),
                        onClick: () => {
                          setSelected(
                            (
                              v 
                            ).toJSON() 
                          );
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
                      )
                    )
                  );
                },
              } ,
              ,
            ],
          } 
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      )
      , React.createElement(Drawer, {
        open: !!drawerOpen,
        placement: "right",
        width: "60%",
        onClose: (e) => setSelected(null),
        extra: [
          React.createElement(Button, {
            icon: React.createElement(Icon, { icon: "fa:save", __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}} ),
            onClick: async () => {
              await saveDrawerItem(), setSelected(null);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}

        , React.createElement(Row, { style: { margin: 10, height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
          , React.createElement(Col, { offset: 0, span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
            , drawerItem && (
              React.createElement(Descriptions, { title: t("wehn:admin.decoder.master.title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
                , React.createElement(React.Fragment, null
                  , React.createElement(Descriptions.Item, {
                    label: t("wehn:admin.decoder.master.label"),
                    span: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}

                    , drawerItem.label
                  )
                  , React.createElement(Descriptions.Item, {
                    label: t("wehn:admin.decoder.master.id"),
                    span: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}

                    , React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}, drawerItem.objectId)
                  )
                  , React.createElement(Descriptions.Item, {
                    label: t("wehn:admin.decoder.master.type"),
                    span: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}

                    , drawerItem.type
                  )
                )
              )
            )
            , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 162}})
            , drawerItem && drawerItem.type === "BitsOnly" && (
              React.createElement(React.Fragment, null
                , React.createElement(BitsDecoder, {
                  ...(drawerItem ),
                  update: (updated) => {
                    setDrawerItem(
                      produce((draft) => {
                        draft.config.bits = updated;
                      })
                    );
                  },
                  title: t("wehn:admin.decoder.bits.title"),
                  offset: 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
                )
                , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 177}} )
                , React.createElement(BitsDecoder, {
                  ...(drawerItem ),
                  update: (updated) => {
                    setDrawerItem(
                      produce((draft) => {
                        draft.config.bits = updated;
                      })
                    );
                  },
                  title: t("wehn:admin.decoder.bits.title"),
                  offset: 8, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
                )
                , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 190}} )
                , React.createElement(BitsDecoder, {
                  ...(drawerItem ),
                  update: (updated) => {
                    setDrawerItem(
                      produce((draft) => {
                        draft.config.bits = updated;
                      })
                    );
                  },
                  title: t("wehn:admin.decoder.bits.title"),
                  offset: 16, __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
                )
                , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 203}} )
                , React.createElement(BitsDecoder, {
                  ...(drawerItem ),
                  update: (updated) => {
                    setDrawerItem(
                      produce((draft) => {
                        draft.config.bits = updated;
                      })
                    );
                  },
                  title: t("wehn:admin.decoder.bits.title"),
                  offset: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 204}}
                )
              )
            )
            , drawerItem && drawerItem.type === "BitsNumber" && (
              React.createElement(React.Fragment, null
                , React.createElement(BitsNumberDecoder, {
                  decoder: drawerItem,
                  update: (udpatedOptions) => {
                    setDrawerItem(udpatedOptions);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
                )
              )
            )
            , drawerItem && drawerItem.type === "NumberNumber" && (
              React.createElement(React.Fragment, null
                , React.createElement(NumberNumberDecoder, {
                  decoder: drawerItem,
                  update: (udpatedOptions) => {
                    setDrawerItem(udpatedOptions);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 230}}
                )
              )
            )
            , drawerItem && drawerItem.type === "Number" && (
              React.createElement(React.Fragment, null
                , React.createElement(NumberDecoder, {
                  number: drawerItem.config.number,
                  update: (udpatedOptions) => {
                    setDrawerItem(
                      produce((draft) => {
                        draft.config.number = udpatedOptions;
                      })
                    );
                  },
                  title: t("wehn:admin.decoder.number4b.title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 240}}
                )
              )
            )
          )
        )
      )
    )
  );
};
