import Parse from "parse";











export class WEHN_IOT_TruckLoadType extends Parse.Object {
  static __initStatic() {this.className = "WEHN_IOT_TruckLoadType"}

  constructor(data) {
    super("WEHN_IOT_TruckLoadType", data );
  }

  get key() {
    return super.get("key");
  }
  set key(value) {
    super.set("key", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
} WEHN_IOT_TruckLoadType.__initStatic();

Parse.Object.registerSubclass("WEHN_IOT_TruckLoadType", WEHN_IOT_TruckLoadType);
