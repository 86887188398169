const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\TrackerPage\\FilterComponent.tsx";import { Button, Select, Typography } from "antd";
import { useTranslation } from "@opendash/core";
import React from "react";
import useFilters from "../../hooks/useFilters";

import { useNavigate } from "react-router";









const FilterComponent = ({
  activeFilters,
  setActiveFilters,
  style = {},
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { filters, queryLoading: filterLoading, reload, error } = useFilters();
  return (
    React.createElement('div', { style: { background: "#fff", ...style }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        , t("wehn:filter.label_plural")
      ), " "
      , React.createElement(Select, {
        style: { width: "100%" },
        mode: "multiple",
        value: activeFilters,
        options: filters.map((filter) => ({
          label: filter.get("label"),
          value: filter.id,
        })),
        onSelect: (value) => {
          setActiveFilters(
            [...activeFilters, value],
            [...activeFilters, value]
              .map((f) => {
                return filters.find((cf) => cf.id === f) || null;
              })
              .filter((f) => !!f)
          );
        },
        onDeselect: (value) => {
          const ids = activeFilters.filter((f) => f !== value);
          const fFilters = filters.filter((f) =>
            ids.includes(f.id)
          );
          setActiveFilters(ids, fFilters);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
)
      , React.createElement('div', {
        style: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}

        , React.createElement(Button, {
          type: "link",
          onClick: () => {
            navigate("/admin/filter");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}

          , t("wehn:filter.create")
        )
        , React.createElement(Button, { type: "link", onClick: () => {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
          , t("wehn:filter.save_view")
        )
      )
    )
  );
};

export default FilterComponent;
