const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\Decoder\\DecoderOptionsComponent.tsx";import * as React from "react";

import { useTranslation, } from "@opendash/core";

import {

  Row,
  Col,




} from "antd";



export default (props) => {
  const t = useTranslation();
  return (
    React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}})
    )
  );
};
