const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\TrackerPage\\VehicleTracker.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {

  Loading,


  produce,
  useOpenDashServices,
  useSource,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  WidgetStatic,


  useDashboardsBySource,
} from "@opendash/plugin-monitoring";
import { PageHeader } from "@ant-design/pro-layout";
import {


  useDataItems,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Description } from "@opendash/ui";
import {


  Button,
  Descriptions,

  Dropdown,

  Layout,


  Switch,
  Tabs,


  Tree,

  Typography,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";

import React, { useState } from "react";

import useSourceImage from "../../hooks/useSourceImage";
import { useNavigate } from "react-router";
import { useVehicleData } from "../../hooks/useVehicleData";
import VehicleTag from "./VehicleTag";

import useFilters from "../../hooks/useFilters";
import useFilteredItems from "../../hooks/useFilteredItems";
import TrackerMapComponent from "../TrackerMapComponent";
import FilterComponent from "./FilterComponent";
import TrackerTableComponent from "./TrackerTableComponent";
import useVehicleMasterInfo from "../../hooks/useVehicleMasterInfo";

const basicConfig = {
  visulization: "line",
  encoded: [
    {
      steps: [0, 20, 40],
      range: [0, 40],
      colors: ["#ff0000", "#00ff00", "#00ff00"],
      data: null,
    },
  ],
  minDistance: 0,
  maxWaitTime: 300000,
  zoomOnData: true,
  translationPrefix: "miaas:widgets.historymap.popUp.prefix.",
  _history: {
    value: 1,
    unit: "hour",
    futureValue: 0,
    futureUnit: "week",
    aggregation: false,
    historyType: "relative",
    live: true,
  },
  _dimensions: [] ,
};

const VehicleTracker = (props) => {
  const { SourceService } = useOpenDashServices();
  const [cSource, setSource, allSources, loading, enabled] = useSource();
  const sources = [cSource, ...SourceService.getDescendents(cSource)];
  const [expandedKeys, setExpandedKeys] = React.useState([]);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const { filters, queryLoading: filterLoading, reload, error } = useFilters();
  const { sourceImages, queryLoading } = useSourceImage(sources);
  const [activeTab, setActiveTab] = useState("map");
  const t = useTranslation();
  const items = useDataItems();

  const { itemsInOverview, attachedVehicle } = useVehicleData();
  const { vehicles: vMasterInfo } = useVehicleMasterInfo();
  const DataService = useDataService();
  const dashboards = useDashboardsBySource();
  const navigate = useNavigate();
  const [activeFilterIds, setActiveFilterIds] = useState([]);
  const [activeFilters, setActiveFilters] = useState

([]);
  const { filteredItems, filteredSources } = useFilteredItems(
    activeFilters ? activeFilters : []
  );

  const dbItems = dashboards.map((db) => ({
    key: db.id,
    label: db.name,
  }));
  if (loading) {
    return React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}, loading && React.createElement(Loading, { message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}} ));
  }

  const getSelectedSourceTree = (
    cSource
  ) => {
    const parent = allSources.find((s) => s.id === cSource.parent);
    if (parent) {
      return [cSource, ...getSelectedSourceTree(parent)];
    }
    return [cSource];
  };

  React.useEffect(() => {
    setExpandedKeys(getSelectedSourceTree(cSource).map((s) => s.tag));
  }, [cSource.id]);

  const options = produce(basicConfig, (draft) => {
    const passedSources = filteredSources.passed || [];
    const positionItemsInSource = items
      .filter((item) => item.id === "Position")
      .filter((item) => {
        return passedSources.indexOf(item.source) > -1;
      })
      .map((item) => {
        return [item.source, item.id, 0];
      });
    draft._dimensions = positionItemsInSource;
  });

  const getChildMenu = (source) => {
    const children = allSources
      .filter((s) => {
        return s.parent === source.id;
      })
      .map((s) => getChildMenu(s));
    return {
      title: (
        React.createElement('div', {
          style: {
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.1em",
            fontWeight: "light",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}

          , children.length === 0 && React.createElement(Icon, { icon: "fa:car", __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}} )
          , React.createElement('span', { style: { fontWeight: 100 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}, " " , source.name)
        )
      ),
      key: source.tag,
      icon: React.createElement(Icon, { icon: "fa:car", __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}),
      children: children.length > 0 ? children : undefined,
      //type: children.length > 0 ? "group" : "item",
    };
  };

  const switchToDashboard = (dashboardId) => {
    const dashboard = dashboards.find((db) => db.id === dashboardId);
    if (dashboard) {
      navigate(`/monitoring/dashboards/${cSource.id}/${dashboard.id}`);
    }
  };

  const tabs = [
    {
      key: "table",
      label: "Listenansicht",
      children: (
        React.createElement(TrackerTableComponent, {
          sources: 
            (filteredSources.passed || []).map((sourceTag) => {
              return allSources.find((s) => s.tag === sourceTag);
            }) 
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
        )
      ),
    },
    {
      key: "map",
      label: "Kartenansicht",
      children: (
        React.createElement('div', {
          style: {
            display: "flex",
            flexDirection: "column",
            height:
              itemsInOverview.length > 0
                ? "calc(100vh - 263px)"
                : "calc(100vh - 63px)",

            position: "relative",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}

          , activeTab === "map" && (
            React.createElement(TrackerMapComponent, { options: options, __self: this, __source: {fileName: _jsxFileName, lineNumber: 216}})
          )
        )
      ),
    },
  ];

  return (
    React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 224}}
      , React.createElement(Sider, {
        width: 300,
        style: {
          backgroundColor: "#343f70",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 225}}


        , React.createElement(Tree, {
          style: {
            backgroundColor: "#343f70",
            color: "#fff",
            padding: "8px",
            width: "100%",
          },
          //theme="dark"pnpm
          //mode="inline"
          expandedKeys: expandedKeys,
          showLine: true,
          showIcon: false,
          defaultSelectedKeys: sources.map((s) => s.tag),
          treeData: allSources
            .filter((s) => !s.parent)
            .map((source) => getChildMenu(source)),
          onSelect: (event) => {
            const newSource = allSources.find((s) => s.tag === event[0]);
            if (newSource) setSource(newSource.id);
          },
          onExpand: (expanded) => {
            setExpandedKeys(expanded );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
        )
      )

      , React.createElement(Content, { style: { margin: "0 0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}
        /* {
          <Breadcrumb style={{ margin: "16px 16px" }}>
            {getSelectedSourceTree(cSource)
              .filter((_, idx) => idx > 0)
              .reverse()
              .map((s) => (
                <Breadcrumb.Item>{s.name}</Breadcrumb.Item>
              ))}
          </Breadcrumb>
        } */
        , React.createElement(PageHeader, {
          style: {
            margin: "16px",
            backgroundColor: "#fff",
            padding: "16",
          },
          title: cSource.name,
          extra: [
            SourceService.getDescendents(cSource).length > 0 ? (
              React.createElement('div', { style: { marginRight: "16px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 278}}
                , React.createElement(Switch, {
                  checked: filterEnabled,
                  onChange: (checked) => {
                    setFilterEnabled(checked);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}
), " ", "Filter"

              )
            ) : null,
            dbItems.length > 0 ? (
              React.createElement(Dropdown.Button, {
                onClick: () => {
                  switchToDashboard(dbItems[0].key);
                },
                menu: {
                  items: dbItems,
                  onClick: (e) => {
                    switchToDashboard(e.key);
                  },
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 289}}

                , dbItems[0].label
              )
            ) : (
              React.createElement(Button, {
                type: "dashed",
                onClick: () => {
                  navigate("/monitoring/dashboards?db_create=!t");
                },
                icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 308}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}

                , t("wehn:tracker.dashboards.add")
              )
            ),
          ],
          avatar: 
            queryLoading || !sourceImages[cSource.id]
              ? { src: undefined, shape: "square" }
              : { src: sourceImages[cSource.id], shape: "square" }
          ,
          tags: React.createElement(VehicleTag, { vehicle: attachedVehicle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 319}}), __self: this, __source: {fileName: _jsxFileName, lineNumber: 269}}

          , filterEnabled && (
            React.createElement(FilterComponent, {
              style: { marginBottom: "16px" },
              activeFilters: activeFilterIds,
              setActiveFilters: (ids, cFilters) => {
                setActiveFilters(cFilters);
                setActiveFilterIds(ids);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 322}}
            )
          )
          , itemsInOverview.length > 0 && (
            React.createElement('div', {
              style: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "stretch",
                justifyContent: "space-between",
                margin: "16px 0px",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 332}}

              , React.createElement(Descriptions, { bordered: true, style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}
                , React.createElement(Description, { label: "Fahrgestellnummer", span: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 343}}
                  , _optionalChain([vMasterInfo, 'optionalAccess', _ => _[0], 'optionalAccess', _2 => _2.get, 'call', _3 => _3("fahrgestellnummer")])
                )
                , React.createElement(Description, { label: "ID", span: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}
                  , _optionalChain([vMasterInfo, 'optionalAccess', _4 => _4[0], 'optionalAccess', _5 => _5.get, 'call', _6 => _6("vehicleID")])
                )
                , React.createElement(Description, { label: "Info", span: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 349}}
                  , _optionalChain([vMasterInfo, 'optionalAccess', _7 => _7[0], 'optionalAccess', _8 => _8.get, 'call', _9 => _9("info")])
                )
              )
            )
          )
        )

        , itemsInOverview.length > 0 && (
          React.createElement('div', {
            style: {
              display: "flex",
              flexDirection: "row",
              height: "200px",
              gap: "8px",
              margin: "16px",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}

            , itemsInOverview.map((cItem) => {
              const [item, index, icon] = cItem 



;
              return (
                React.createElement('div', {
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    flex: "1 1 0",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 374}}

                  , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 382}}
                    , DataService.getItemName(item, index, true)
                  )
                  , React.createElement('div', {
                    style: {
                      flexGrow: 1,
                      border: "1px solid #d9d9d9",
                      backgroundColor: "#fff",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}

                    , React.createElement(WidgetStatic, {
                      type: "opendash-widget-hc-kpi",
                      config: {
                        hideTimeStamp: true,
                        hideTitle: true,
                        icon: icon || "fa:info",
                        _sources: [],
                        _items: [],
                        _dimensions: [[item.source, item.id, index]],
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 392}}
                    )
                  )
                )
              );
            })
          )
        )
        , React.createElement('div', {
          style: {
            display: "flex",
            flexDirection: "column",
            height:
              itemsInOverview.length > 0
                ? "calc(100vh - 217px)"
                : "calc(100vh - 17px)",
            margin: "0 16px",
            // border: "1px solid #d9d9d9",
            position: "relative",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 409}}

          , React.createElement(Tabs, {
            items: tabs,
            defaultActiveKey: sources.length > 1 ? "table" : "map",
            style: { backgroundColor: "#fff" },
            onChange: (key) => {
              setActiveTab(key);
            },
            renderTabBar: (props, DefaultTabBar) => {
              return (
                React.createElement(DefaultTabBar, {
                  ...props,
                  style: {
                    padding: "0px 8px",
                    margin: "0px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 431}}
)
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 422}}
)
        )
      )
    )
  );
};

export { VehicleTracker };
