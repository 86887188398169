const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\Decoder\\BitsDecoder.tsx";import * as React from "react";

import { useTranslation, produce, } from "@opendash/core";

import { Row, Col, Input, Switch, Descriptions } from "antd";








export default (props) => {
  const t = useTranslation();

  return (
    React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , React.createElement(Descriptions, { title: t(props.title), bordered: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
          , props.config.bits.map((bit, index) => {
            if (index < props.offset || index > props.offset + 7) return;
            return (
              React.createElement(React.Fragment, null
                , React.createElement(Descriptions.Item, {
                  key: "switch-" + index,
                  label: "Bit " + (index + 1),
                  span: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}

                  , React.createElement(Switch, {
                    checked: bit.enabled,
                    onChange: () => {
                      props.update(
                        produce(props.config.bits, (draft) => {
                          draft[index].enabled = !bit.enabled;
                        })
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
                  )
                )
                , React.createElement(Descriptions.Item, { span: 6, key: "label-" + index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
                  , React.createElement(Input, {
                    disabled: !bit.enabled,
                    value: bit.label,
                    onChange: (e) => {
                      const val = e.target.value;
                      props.update(
                        produce(props.config.bits, (draft) => {
                          draft[index].label = val;
                        })
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
)
                )
              )
            );
          })
        )
      )
    )
  );
};
