 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {


  useOpenDashServices,
} from "@opendash/core";

import React from "react";



import {


  useDataItems,
} from "@opendash/plugin-timeseries";
import {
  filterRecursiv,

  prepareRuleForSource,
} from "../Helper";

import { useDataService } from "@opendash/plugin-timeseries";

export default function useFilteredItems(filters) {
  const DataService = useDataService();
  const preparedFilters =
    filters && filters.length > 0 ? filters.map((filter) => filter.values) : [];

  const items = useDataItems();
  const { SourceService } = useOpenDashServices();
  const evaluateRule = React.useCallback(
    (
      rule,
      items
    ) => {
      if (!rule) return "passed";
      if (rule.type === "rule") {
        const item = _optionalChain([items, 'access', _ => _[rule.id], 'optionalAccess', _2 => _2[0]]);
        if (!item) {
          return "not-found";
        }
        try {
          const value = DataService._getValueOrThrowSync(item);
          const preppedRule = prepareRuleForSource(rule, item.source);

          return filterRecursiv(preppedRule, value) ? "passed" : "not-passed";
        } catch (error) {
          console.error("No value evaluation error", rule, error);
          return "not-passed";
        }
      } else {
        //@ts-expect-error
        const childResults = Object.groupBy(rule.children, (innerRule) => {
          return evaluateRule(innerRule, items);
        });
        if (childResults["not-found"]) return "not-found";
        if (childResults["not-passed"]) return "not-passed";
        return "passed";
      }
    },
    []
  );

  const filtered = React.useMemo(() => {
    //@ts-expect-error
    const bySource = Object.groupBy(items, (item) => item.source);
    [
      SourceService.getCurrent(),
      ...SourceService.getDescendents(SourceService.getCurrent()),
    ].forEach((source) => {
      if (bySource[source.tag]) return;
      bySource[source.tag] = [];
    });
    const bySourceAndId = {} 


;
    Object.keys(bySource).forEach((sourceTag) => {
      //@ts-expect-error
      const group = Object.groupBy(
        bySource[sourceTag],
        (item) => item.id
      );
      Object.keys(group).forEach((id) => {
        group[id] = group[id].flat();
      });
      bySourceAndId[sourceTag] = group;
    });
    //@ts-expect-error
    const filteredSources = Object.groupBy(
      Object.keys(bySourceAndId),
      (cSource) => {
        //@ts-expect-error
        const childResults = Object.groupBy(
          preparedFilters,
          (filter) => {
            return evaluateRule(filter, bySourceAndId[cSource]);
          }
        );

        if (childResults["not-found"]) return "not-found";
        if (childResults["not-passed"]) return "not-passed";
        return "passed";
      }
    );

    const filteredItems = !filteredSources["passed"]
      ? []
      : filteredSources["passed"].reduce(
          (acc, sourceTag) => {
            return [...acc, ...bySource[sourceTag]];
          },
          []
        );

    return { filteredItems, filteredSources };
  }, [filters]);

  return filtered;
}
