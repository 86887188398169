import "antd/dist/reset.css";
import "./highcharts.config";
import "./leaflet.config";
import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { getCurrentLanguageSync } from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { GeoPlugin } from "@opendash/plugin-geo";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { MonitoringPlugin } from "@opendash/plugin-monitoring";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
import { ParseMonitoringPlugin } from "@opendash/plugin-parse-monitoring";
import { TimeseriesPlugin } from "@opendash/plugin-timeseries";
import { MobilityPlugin } from "@opendash/plugin-mobility";
import dayjs from "dayjs";
import { WehnPlugin } from "./plugin";

dayjs.locale(getCurrentLanguageSync());

init("opendash", async (factory) => {
  // Logo

  factory.ui.setLogoImage(require("../logo/icon-long.png"));

  factory.ui.disableHeaderSourcePicker();

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  factory.registerAntDesignTranslation(
    "zh_Hans",
    () => import("antd/lib/locale/zh_CN")
  );

  // parse class translations

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/parse/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/parse/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "parse-custom",
    async () => await import("./translations/parse/zh_Hans.json")
  );

  factory.registerTranslationResolver(
    "de",
    "widgets",
    async () => await import("./translations/widgets/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "widgets",
    async () => await import("./translations/widgets/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "widgets",
    async () => await import("./translations/widgets/zh_Hans.json")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(new TimeseriesPlugin());
  await factory.use(new MonitoringPlugin());
  await factory.use(new MobilityPlugin());

  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      useLiveQueries: false,
    })
  );
  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      useLiveQueries: false,
    })
  );
  await factory.use(
    new ParseMonitoringPlugin({
      liveQueries: false,
    })
  );

  await factory.use(
    new OpenwarePlugin({
      secure: true,
      host: "irs.wehn-group.de",
      //host: "localhost:4567",
      disableFeature: {
        menu: {
          SensorsGroup: false,
          DataPoints: false,
          DataSources: true,
        },
        slideshow: true,
        dataCollection: true,
        VKPI: true,
        forms: {
          dateBySensor: true,
        },
        reporting: false,
      },
    })
  );

  $parse.ui.setClassConfig({
    className: "OD3_Source",
    titleFields: ["name"],
    displayFields: ["name", "tag", "parent"],
    createFields: ["name", "tag", "parent"],
    editFields: ["name", "tag", "parent"],
    translationPrefix: "wehn:classes.",
    defaultACL: () => {
      if (!$parse.user.id()) {
        return {};
      }

      return {
        ["" + $parse.user.id()]: {
          read: true,
          write: true,
        },
      };
    },
  });

  await factory.use(new HighchartsPlugin());
  await factory.use(new GeoPlugin());
  await factory.use(new WehnPlugin());

  $parse.ui.setClassConfig({
    className: "_Role",
    titleFields: ["label"],
    displayFields: ["label", "name", "users", "createdAt", "updatedAt"],
    createFields: ["label", "name"],
    editFields: ["label", "name", "users"],
    defaultACL: () => {
      if (!$parse.user.id()) {
        return {};
      }

      return {
        ["" + $parse.user.id()]: {
          read: true,
          write: true,
        },
      };
    },
  });
  $parse.ui.setDefaultView("_Role", {
    type: "table",
  });

  // Navigation:

  // factory.registerStaticNavigationItem({
  //   id: "monitoring/dashboard",
  //   group: "monitoring",
  //   place: "frontpage",
  //   order: 1,
  //   label: "opendash:monitoring.label",
  //   icon: "fa:chart-line",
  //   color: "#4385c2",
  //   link: "/monitoring/dashboards",
  //   routeCondition: "**",
  //   activeCondition: "/",
  // });

  // factory.registerStaticNavigationGroup({
  //   label: "parse-admin:admin.label",
  //   order: 10,
  //   id: "admin/parse",
  // });
  // factory.registerStaticNavigationGroup({
  //   label: "openware:reporting.label",
  //   order: 30,
  //   id: "admin/reporting",
  // });
  factory.registerStaticNavigationItem({
    id: "wehn/tracker",
    group: "wehn",
    place: "frontpage",
    order: 1,
    label: "wehn:tracker.label",
    icon: "fa:car",
    color: "#273f73",
    link: "/irs/tracker",
    routeCondition: "**",
    activeCondition: "/",
  });

  // factory.registerStaticNavigationItem({
  //   id: "admin/parse/item",
  //   group: "admin/parse",
  //   place: "frontpage",
  //   order: 100,
  //   label: "opendash:admin.label",
  //   icon: "fa:cogs",
  //   color: "#676767",
  //   link: "/admin/parse/_Role",
  //   routeCondition: "**",
  //   activeCondition: "/",
  // });

  // factory.registerStaticNavigationItem({
  //   id: "admin/parse/role",
  //   group: "admin/parse",
  //   place: "sidebar",
  //   order: 10,
  //   label: "parse-custom:classes._Role.label_plural",
  //   icon: "fa:building",
  //   link: "/admin/parse/_Role",
  //   routeCondition: "/admin/**",
  //   activeCondition: "/admin/parse/_Role",
  // });
  // factory.registerStaticNavigationItem({
  //   id: "admin/parse/user",
  //   group: "admin/parse",
  //   place: "sidebar",
  //   order: 20,
  //   label: "parse-custom:classes._User.label_plural",
  //   icon: "fa:users",
  //   link: "/admin/parse/_User",
  //   routeCondition: "/admin/**",
  //   activeCondition: "/admin/parse/_User",
  // });
  factory.registerStaticNavigationGroup({
    label: "wehn:classes.OD3_Source.label_plural",
    order: 30,
    id: "admin/vehicle",
  });
  factory.registerStaticNavigationItem({
    id: "admin/vehicle/sources",
    group: "admin/vehicle",
    place: "sidebar",
    order: 10,
    label: "wehn:classes.OD3_Source.label_plural",
    icon: "fa:car",
    link: "/admin/parse/OD3_Source",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/OD3_Source",
  });
}).then((app) => {
  console.log("init open.DASH");
});
