import Parse from "parse";




















export class OD3_Report extends Parse.Object {
  static __initStatic() {this.className = "OD3_Report"}

  constructor(data) {
    super("OD3_Report", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get items() {
    return super.get("items");
  }
  set items(value) {
    super.set("items", value);
  }
  get masterData() {
    return super.get("masterData");
  }
  set masterData(value) {
    super.set("masterData", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get options() {
    return super.get("options");
  }
  set options(value) {
    super.set("options", value);
  }
  get template() {
    return super.get("template");
  }
  set template(value) {
    super.set("template", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Report.__initStatic();

Parse.Object.registerSubclass("OD3_Report", OD3_Report);
