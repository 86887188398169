import Parse from "parse";














export class WEHN_IOT_VehicleFilter extends Parse.Object {
  static __initStatic() {this.className = "WEHN_IOT_VehicleFilter"}

  constructor(data) {
    super("WEHN_IOT_VehicleFilter", data );
  }

  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get values() {
    return super.get("values");
  }
  set values(value) {
    super.set("values", value);
  }
} WEHN_IOT_VehicleFilter.__initStatic();

Parse.Object.registerSubclass("WEHN_IOT_VehicleFilter", WEHN_IOT_VehicleFilter);
