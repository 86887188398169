 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import { useParseQuery } from "parse-hooks";
import React from "react";
import Parse from "parse";
export default function useSourceImage(sources) {
  const [sourceImages, setSourceImages] = React.useState

({});

  const imageQuery = React.useMemo(() => {
    return new Parse.Query("OD3_Source").containedIn(
      "objectId",
      sources.map((s) => s.id)
    );
  }, [_optionalChain([sources, 'optionalAccess', _ => _[0], 'optionalAccess', _2 => _2.id])]);
  const {
    result,
    error,
    loading: queryLoading,
    reload,
  } = useParseQuery(imageQuery);

  React.useEffect(() => {
    if (result) {
      const record = {} ;
      result.forEach((source) => {
        record[source.id] = _optionalChain([(source.get("image") ), 'optionalAccess', _3 => _3.url, 'call', _4 => _4()]);
      });
      setSourceImages(record);
    }
  }, [result]);

  return { sourceImages, queryLoading, error, reload };
}
