const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\VehicleFilter\\EditFilterValuesDialog.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {
  Loading,

  produce,
  useOpenDashServices,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import {

  useDataItems,
  useDataService,
} from "@opendash/plugin-timeseries";
import {
  Button,
  Descriptions,
  Divider,
  Drawer,

  Result,
  Typography,
} from "antd";
import React from "react";

import { useParseQueryFirst } from "parse-hooks";
import {

  WEHN_IOT_VehicleFilter,

} from "../../types";
import Parse from "parse";
import {
  AlarmCondition,


} from "@opendash/plugin-monitoring";
import { Icon } from "@opendash/icons";




const EditFilterValueDialog = (props) => {
  const [filter, setFilter] = useUrlParam(
    "editFilter",
    null ,
    "array"
  );
  const items = useDataItems();
  const { SourceService } = useOpenDashServices();
  const sources = SourceService.getAll();
  const DataService = useDataService();
  const t = useTranslation();
  const ruleCandidates = React.useMemo(() => {
    //@ts-ignore
    return Object.groupBy(items, (item) => {
      return `${item.id}_${item.valueTypes.length}`;
    });
  }, [items]);

  const [filterId, dialogType] = filter ? filter : [null, null];

  const query = React.useMemo(() => {
    return new Parse.Query(WEHN_IOT_VehicleFilter).equalTo(
      "objectId",
      filterId || ""
    );
  }, [filterId]);
  const {
    result: filterData,
    reload,
    loading,
    error,
  } = useParseQueryFirst(query);
  const [filterState, setFilterState] = React.useState(
    _optionalChain([filterData, 'optionalAccess', _ => _.toJSON, 'call', _2 => _2()])
  );
  const itemToUse = items.find(
    (item) =>
      item.valueTypes[0].type !== "Geo" && item.valueTypes[0].type !== "Object"
  );
  const [filterRule, setFilterRule] = React.useState(
    _optionalChain([filterData, 'optionalAccess', _3 => _3.values]) || {}
  );

  React.useEffect(() => {
    setFilterRule(_optionalChain([filterData, 'optionalAccess', _4 => _4.values]) || {});
    setFilterState(_optionalChain([filterData, 'optionalAccess', _5 => _5.toJSON, 'call', _6 => _6()]));
  }, [filterData]);

  const save = () => {
    _optionalChain([filterData, 'optionalAccess', _7 => _7.set, 'call', _8 => _8("label", filterState.label)]);
    _optionalChain([filterData, 'optionalAccess', _9 => _9.set, 'call', _10 => _10("values", filterRule)]);
    _optionalChain([filterData, 'optionalAccess', _11 => _11.save, 'call', _12 => _12(), 'access', _13 => _13.then, 'call', _14 => _14(() => {
      reload();
      setFilter(null);
    })]);
  };
  console.log({ filter, itemToUse });
  return (
    React.createElement(Drawer, {
      open: dialogType === "values",
      placement: "bottom",
      height: "90%",
      extra: React.createElement(Button, { icon: React.createElement(Icon, { icon: "fa:save", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}), onClick: save, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} ),
      onClose: () => {
        setFilter(null);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}

      , loading && React.createElement(Loading, { message: "...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}} )
      , error && (
        React.createElement(Result, { status: "error", title: "Error", subTitle: error.message, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}} )
      )
      , !filterRule && !error && (
        React.createElement(Result, { status: "error", title: "Error", subTitle: "Filter not found", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}} )
      )
      , filterData && (
        React.createElement(React.Fragment, null
          , React.createElement(Descriptions, {
            title: _optionalChain([filterState, 'optionalAccess', _15 => _15.label]),
            bordered: true,
            column: 1,
            items: [
              {
                key: 0,
                label: t("wehn:admin.filter.edit.label"),
                children: (
                  React.createElement(Typography.Text, {
                    editable: {
                      onChange(value) {
                        setFilterState(
                          produce((draft) => {
                            draft.label = value;
                          })
                        );
                      },
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}

                    , _optionalChain([filterState, 'optionalAccess', _16 => _16.label])
                  )
                ),
              },
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
)
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 144}})
          , React.createElement(AlarmCondition, {
            triggerItem: [_optionalChain([itemToUse, 'optionalAccess', _17 => _17.source]) || "", _optionalChain([itemToUse, 'optionalAccess', _18 => _18.id]) || "", 0],
            value: filterRule,
            onChange: (newRule) => {
              setFilterRule(newRule);
            },
            customItems: (
              cCondition,
              items
            ) => {
              return items
                .flatMap((item) => {
                  return item.valueTypes
                    .map((dim, index) => {
                      try {
                        const matches =
                          ruleCandidates[
                            `${item.id}_${item.valueTypes.length}`
                          ];
                        return {
                          label: `${DataService.getItemName(
                            item,
                            index,
                            true
                          )}  (${matches.length} Units)`,
                          value: DataService.keyForIdentifier([
                            item.source,
                            item.id,
                            index,
                          ]),
                          type: item.valueTypes[index].type,
                        };
                      } catch (e) {
                        return { label: "error item unkown", value: "[]" };
                      }
                    })
                    .filter((item) => item.type !== "Geo");
                })
                .filter((item, index, items) => {
                  return (
                    items.findIndex((cItem) => item.label === cItem.label) ===
                    index
                  );
                });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
)
        )
      )
    )
  );
};

export default EditFilterValueDialog;
