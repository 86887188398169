const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\wehn-irs-opendash\\src\\js\\plugin\\components\\TrackerMapComponent.tsx";import {

  WidgetStatic,

} from "@opendash/plugin-monitoring";
import { Empty } from "antd";
import React from "react";




const TrackerMapComponent = ({ options }) => {
  return (
    React.createElement(React.Fragment, null
      , options._dimensions.length >= 1 && (
        React.createElement(WidgetStatic, {
          type: "opendash-widget-leaflet-history",
          config: options, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
)
      )
      , options._dimensions.length < 1 && React.createElement(Empty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}})
    )
  );
};

export default TrackerMapComponent;
