import Parse from "parse";















export class MIAAS_Geographies extends Parse.Object {
  static __initStatic() {this.className = "MIAAS_Geographies"}

  constructor(data) {
    super("MIAAS_Geographies", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get geo() {
    return super.get("geo");
  }
  set geo(value) {
    super.set("geo", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} MIAAS_Geographies.__initStatic();

Parse.Object.registerSubclass("MIAAS_Geographies", MIAAS_Geographies);
