 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import { useParseQuery } from "parse-hooks";
import React from "react";
import Parse from "parse";
import {
  OD3_Source,


  WEHN_Vehicles,

} from "../types";

import { useDataService } from "@opendash/plugin-timeseries";
export default function useTrackerTableData(
  sources,
  colConfig
) {
  const DataService = useDataService();
  const OD3_SourceQuery = React.useMemo(() => {
    return new Parse.Query(OD3_Source).containedIn(
      "objectId",
      sources.map((source) => source.id)
    );
  }, [sources]);

  const {
    result: vSources,
    error: sourceError,
    loading: sourceLoading,
    reload: reloadSource,
  } = useParseQuery(OD3_SourceQuery);

  const vehicleQuery = React.useMemo(() => {
    return new Parse.Query(WEHN_Vehicles)
      .containedIn("source", vSources || [])
      .include("source");
  }, [vSources]);
  const {
    result: vehicles,
    error: vError,
    loading: vLoading,
    reload: vReload,
  } = useParseQuery(vehicleQuery);

  const data = sources.map((source_interface) => {
    const entry = {};
    const source = _optionalChain([vSources, 'optionalAccess', _ => _.find, 'call', _2 => _2(
      (vSource) => vSource.id === source_interface.id
    )]);
    colConfig.forEach((col) => {
      if (!source) {
        return "-";
      }
      if (col.type === "WEHN_Vehicles") {
        const cVehicle = _optionalChain([vehicles, 'optionalAccess', _3 => _3.find, 'call', _4 => _4(
          (v) => v.get("source").id === source.id
        )]);
        entry[`${col.type}_${col.key}`] =
          _optionalChain([cVehicle, 'optionalAccess', _5 => _5.get, 'call', _6 => _6(col.key )]) || "-";
      }
      if (col.type === "OD3_Source") {
        entry[`${col.type}_${col.key}`] =
          _optionalChain([source, 'optionalAccess', _7 => _7.get, 'call', _8 => _8(col.key )]) || "-";
      }
      if (col.type === "Datapoint") {
        const item = DataService._getOrUndefinedSync(
          "" + source.get("tag"),
          col.key
        );
        entry[`${col.type}_${col.key}`] = "-";
        if (!item) {
          return;
        }

        try {
          const val =
            DataService._getValueOrThrowSync(item).value[col.dimension];
          entry[`${col.type}_${col.key}`] = `${val} ${
            item.valueTypes[col.dimension].unit
          }`;
        } catch (e) {
          return;
        }
      }
    });
    return entry;
  });

  return data;
}
